import React from "react"
import { Helmet } from "react-helmet"
import Tabs from "../../components/Tabs"
import TemplatePage from "../../components/TemplatePage"
import TabelActe from "../../components/legislatie/TabelActe"
import { ActNormativ } from "../../utils/api/acteNormative"
import { getAllTarife } from "../../utils/api/tarife"

const getDenumireForTip = tip => {
  switch (tip) {
    case 0:
      return "Tarife aferente activitatii de distributie"
    case 1:
      return "Tarife activitatilor conexe serviciului de alimentare cu gaze naturale"
    default:
      return "Eroare tip"
  }
}

export default function Tarife({ location }) {
  const [tabValue, setTabValue] = React.useState(0)
  const [items, setItems] = React.useState<ActNormativ[]>([])

  React.useEffect(() => {
    const getTarife = async () => {
      try {
        const apiResponse = await getAllTarife()
        if (apiResponse.type) setItems(apiResponse.data)
      } catch (error) {
        console.log(error)
      }
    }
    getTarife()
  }, [])
  return (
    <TemplatePage
      location={location}
      title="Tarife"
      additionalClassName="templateFullContainerFaqScreen"
    >
      <Helmet>
        <title>Tarife - Gazmir</title>
        <meta
          name="description"
          content="Consulta Tarife aferente activitatii de distributie a gazelor naturale practicate de Gazmir."
        />
        <meta
          name="keywords"
          content="tarife distributie gazmir,gazmir tarife distributie"
        />
      </Helmet>
      {/* <Tabs
        setTabValue={setTabValue}
        tabValue={tabValue}
        tabs={["Distribuție", "Conexe"]}
      /> */}
      {/* <div style={styles.tabelHeader}></div> */}
      {/* <TabelTarife tip={0} items={items.filter(item => item.tip === 0)} /> */}
      <TabelActe items={items} getDenumireForTip={getDenumireForTip} />
    </TemplatePage>
  )
}
